import Vue from "vue"
import axiosInstance from "@/plugins/axios"
import { get, sync } from "vuex-pathify"
import localforage from "@/plugins/localforage"
import AsyncComputed from "vue-async-computed"

import "@/assets/fontawesome/scss/fontawesome.scss"
import "@/assets/fontawesome/scss/light.scss"

import { i18n, loadCustom } from "./plugins/i18n"
import { updateFavIcon } from "@/utils/general"
import vuetify from "./plugins/vuetify"
import NetworkInterceptor from './plugins/network-interceptor'

import router from "./router"
import store from "./store"
import api from "./utils/axios"
import App from "./App"

require("./stylus/main.styl")
require("./plugins/rollbar")
require("./plugins/analytics")

// TO DO: find a better solution for this (this causes layout to flash)
// NOTE: This is a rare edge-case for production
/*
When switching to this portal url from another an orgId param is set by the origin portal
This explicitly tells the destination Portal app which organisation to switch TO.
This solves an edge case where:

Portal A supports both Org A and Org B
Portal C support only Org C
User has access to Org A, B and C.

User uses Portal A under Org B.
User switches to Org C (Portal redirects to Portal C)
From Portal C, use selects Org A.

We recirect to Portal A. But it has Org B cached in local storage as this was the last used org on Portal A.
By overriding the last used org IF an orgId url param was set we avoid the user switching org and getting a
valid but incorrect resulti.

Problems:
(i) dirty - we're outside Vue here
(ii) the layout will flash as updating the cached orgid is async - vue races to load the cached org first

*/
const urlParams = new URLSearchParams(window.location.search)
const orgId = urlParams.get("orgId")
if (orgId) localforage.setItem("organisationId", orgId)

////////////////////////////////////////////////////////////////////////////////////////
// Vue initial config
Vue.use(AsyncComputed)
Vue.config.productionTip = false

// make sure to set this synchronously immediately after loading Vue
// and before `new Vue`
Vue.config.devtools = process.env.NODE_ENV !== "production"
Vue.config.performance = process.env.NODE_ENV !== "production"

////////////////////////////////////////////////////////////////////////////////////////
// DEACTIVATED: Service Worker

// Deactivating service workers. Also renamed public/manifest.json to
// public/manifest_deactivated.json
// https://stackoverflow.com/questions/52800904/disable-pwa-plugin-in-vue-cli-3
// import './registerServiceWorker'

// Get rid of existing service workers
// https://love2dev.com/blog/how-to-uninstall-a-service-worker/
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister()
    }
  })

  navigator.serviceWorker.addEventListener("controllerchange", () => {
    window.location.reload()
  })
}

////////////////////////////////////////////////////////////////////////////////////////
// Add auth headers to fetch requests
// E.g. Potree uses fetch internally, can't make it use axios

Vue.use(NetworkInterceptor, { store });

////////////////////////////////////////////////////////////////////////////////////////
// Axios
// TODO: move into a plugin

Vue.axios = axiosInstance
Object.defineProperties(Vue.prototype, {
  axios: {
    get() {
      return axiosInstance
    },
  },
  $http: {
    get() {
      return axiosInstance
    },
  },
})

////////////////////////////////////////////////////////////////////////////////////////
// Actual Vue instance

new Vue({
  i18n,
  router,
  store,
  vuetify,

  computed: {
    layout: get("layout"),
    layoutError: sync("layoutError"), // TODO: Remove this state property entirely

    lang() {
      return store.state.lang
    },
  },

  watch: {
    // Watch language toggle to load language file
    // Not calling from App.vue to Isolate App.vue for pre-compilation
    layout() {
      this.$vuetify.theme.themes = this.layout.themes
      this.$vuetify.theme.dark = this.layout.dark
      this.$vuetify.theme.rtl = this.layout.rtl

      if (this.layout.logo) updateFavIcon(this.layout.logo)
    },
    lang() {
      loadCustom()
    },
  },

  async created() {
    loadCustom()
  },

  render: (h) => h(App),
}).$mount("#app")
