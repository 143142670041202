// noinspection JSUnresolvedVariable

import Vue from "vue"
import { make } from "vuex-pathify"
import api from "./api"
import { set } from "vue-analytics"
import localforage from "@/plugins/localforage"

const state = {
  user: null,
  token: null,
  mapShare: false,
}

const mutations = {
  ...make.mutations(state),
}

const getters = {
  ...make.getters(state),
}

const actions = {
  ...make.actions("user"),

  async loadTokenFromCache({ commit, dispatch }) {
    const token = await localforage.getItem("token")
    if (!token || state.mapShare) return

    commit("SET_TOKEN", token)

    await dispatch("getProfile")
  },

  async login({ commit, dispatch }, { user, password }) {
    commit("SET_TOKEN", null)

    const response = await api.login({ email: user, password })
    if (response.status === 200) {
      const token = response.data.key
      commit("SET_TOKEN", token)
      await localforage.setItem("token", token)

      await dispatch("getProfile")

      dispatch("maps/loadMaps", [], { root: true })
    }

    return response
  },

  async getProfile({ commit, dispatch }) {
    const response = await api.profile()
    if (response.status === 200) {
      const user = response.data

      commit("SET_USER", user)
      await setAnalyticsProfile(user)

      const userOrgs = user.organisations || []
      const userOrgIds = userOrgs?.map((org) => org.id) || []

      let selectedOrgId = await localforage.getItem("organisationId")
      if (!selectedOrgId || !userOrgIds.includes(selectedOrgId)) {
        // First try to pick organisation by the main domain.
        // If that fails, then pick organisation from one of the alt domains
        selectedOrgId =
          userOrgs.find((org) => org.domain === location.hostname)?.id ||
          userOrgs.find((org) => org.alt_domains.includes(location.hostname))?.id
      }
      dispatch("updateOrganisationId", selectedOrgId, { root: true })
    } else {
      console.error(`auth.store.getProfile: ${response}`)
      dispatch("logout")
    }
  },

  async logout({ commit, getters, dispatch }) {
    await api.logout()
    commit("SET_TOKEN", null)
    await Vue.localStore.removeItem("token")
    dispatch("setImpersonateActive", false, { root: true })
  },

  async reset(_, { email }) {
    return await api.reset(email)
  },

  async resetConfirm(_, { uid, token, new_password1, new_password2 }) {
    return await api.resetConfirm(uid, token, new_password1, new_password2)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export async function setAuthToken(token) {
  if (token) {
    await Vue.localStore.setItem("token", token)
    Vue.axios.defaults.headers.common["Authorization"] = `Token ${token}`
  } else if (await Vue.localStore.getItem("token")) {
    await Vue.localStore.removeItem("token")
    delete Vue.axios.defaults.headers.common["Authorization"]
  }
}

export async function setAnalyticsProfile(profile) {
  if (!profile) return

  if (Vue.rollbar) {
    Vue.rollbar.configure({
      payload: {
        person: {
          id: profile.id,
          username: profile.name,
          email: profile.email,
        },
      },
    })
  }

  // Google Analytics
  set("userId", profile.id)
}
