//set auth headers and append trailing backslash to all fetch requests from our site
//as we can't always control these when coming from 3rd party libraries
//e.g. Potree
const NetworkInterceptor = {
  install(Vue, options) {
    const { store } = options

    const urlAppendBackslash = (url) => {
      if (!url.pathname.endsWith("/")) url.pathname = url.pathname + "/"
      return url
    }

    const getAuthHeader = (authToken, asArray = false) => {
      let headerName = store.get("auth/mapShare") ? "MapShare" : "Authorization"
      if (asArray) return [`${headerName}`, `Token ${authToken}`]
      return { [`${headerName}`]: `Token ${authToken}` }
    }

    //override fetch
    const originalFetch = window.fetch
    window.fetch = function (resource, options = {}) {
      //ignore requests from axios, it should set auth headers correctly
      //this is not currently used - had some issues when setting axios to use fetch
      if (options.headers?.["X-Bypass-Interceptor"] === "true")
        return originalFetch(resource, options)

      const newOptions = { ...options }
      let modifiedResource = resource

      const isRequest = resource instanceof Request
      //setting base to location.origin should be safe - base is ignored if the url is absolute
      let resourceUrl = new URL(
        isRequest ? resource.url : resource.toString(),
        location.origin
      )

      //if this is a request to our own server
      if (resourceUrl.href.includes(location.hostname)) {
        // Access store state
        const authToken = store.get("auth/token")

        // Add auth token to headers
        if (authToken) {
          newOptions.headers = {
            ...(newOptions.headers ?? {}),
            ...getAuthHeader(authToken),
          }
        }

        // Add trailing backslash to API endpoints if missing
        // As DRF expects this and returns 301 redirects if it is missing
        if (resourceUrl.href.includes("/api/")) {
          resourceUrl = urlAppendBackslash(resourceUrl)
          modifiedResource = isRequest
            ? new Request(resourceUrl.toString(), resource)
            : resourceUrl
        }
      }

      return originalFetch(modifiedResource, newOptions)
    }

    // Intercept XMLHttpRequest
    const originalXHR = window.XMLHttpRequest
    window.XMLHttpRequest = function () {
      const xhr = new originalXHR()
      const originalOpen = xhr.open
      let isOwnServer = false
      let bypass = false

      xhr.open = function (method, url, async, user, password) {
        // Create URL object to manipulate the URL
        let resourceUrl = typeof url === "URL" ? url : new URL(url, location.origin)
        isOwnServer = resourceUrl.href.includes(location.hostname)

        // Only modify our own hostname requests
        if (isOwnServer && resourceUrl.href.includes("/api/")) {
          // Add trailing backslash to API endpoints if missing
          resourceUrl = urlAppendBackslash(resourceUrl)
        }

        // Call the original open with potentially modified URL
        return originalOpen.call(this, method, resourceUrl, async, user, password)
      }

      const originalSetRequestHeader = xhr.setRequestHeader
      xhr.setRequestHeader = function (header, value) {
        //ignore requests from axios, it should set auth headers correctly
        if (header === "X-Bypass-Interceptor" && value === "true") bypass = true
        return originalSetRequestHeader.call(this, header, value)
      }

      const originalSend = xhr.send
      xhr.send = function (body) {
        // Only add auth header for our own hostname
        if (!bypass && isOwnServer) {
          const authToken = store.get("auth/token")
          if (authToken) xhr.setRequestHeader(...getAuthHeader(authToken, true))
        }

        return originalSend.call(this, body)
      }

      return xhr
    }
  },
}
export default NetworkInterceptor
